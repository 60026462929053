<template>
  <div>
    <data-list :category="category" detailRouter="/creativity-detail"></data-list>
  </div>
</template>

<script>
import dataList from '@/components/DataList'

export default {
  name: 'CreativityList',

  components: { dataList },

  data () {
    return {
      category: 'Creativity'
    }
  }
}
</script>

<style scoped>

</style>
